// components/DriverAssignmentCalendar.js

import React, { useState, useEffect } from 'react';
import OrdersList from './OrdersList';
import DriverCalendar from './DriverCalendar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/DriverAssignment.css';

const DriverAssignmentCalendar = () => {
    const [drivers, setDrivers] = useState([]);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchDriversAndOrders();
    }, []);

    const fetchDriversAndOrders = async () => {
        setLoading(true);
        try {
            const [driversRes, ordersRes] = await Promise.all([
                axios.get("https://api.jatillforsvar.se/api/drivers"),
                axios.get("https://api.jatillforsvar.se/api/orders")
            ]);
            console.log("Fetched Drivers:", driversRes.data);
            console.log("Fetched Orders:", ordersRes.data);
            setDrivers(driversRes.data);
            setOrders(ordersRes.data);
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Failed to fetch drivers or orders.");
        } finally {
            setLoading(false);
        }
    };

    // Enhanced Callback to handle all order updates
    const handleAssignDriver = (orderId, driverId, deliveryDate, duration, newStatus) => {
        console.log(`Updating Order: ${orderId}`);
        console.log(`New Driver ID: ${driverId}`);
        if (deliveryDate) {
            console.log(`New Delivery Date: ${deliveryDate}`);
        }
        if (duration) {
            console.log(`New Duration: ${duration}`);
        }
        if (newStatus) {
            console.log(`New Status: ${newStatus}`);
        }

        setOrders(prevOrders => prevOrders.map(order => {
            if (order.orderId === orderId) {
                console.log(`Updating Order ${orderId} in state`);
                return {
                    ...order,
                    assignedDriver: driverId !== undefined ? driverId : order.assignedDriver,
                    deliveryDate: deliveryDate ? new Date(deliveryDate).toISOString() : order.deliveryDate,
                    duration: duration !== undefined ? duration : order.duration,
                    status: newStatus !== undefined ? newStatus : order.status
                };
            }
            return order;
        }));
    };

    // Callback to handle bulk assignments
    const handleAssignDriverBulk = async (orderIds, driverId) => {
        console.log(`Bulk Assigning Driver ID: ${driverId} to Orders: ${orderIds}`);
        setOrders(prevOrders => prevOrders.map(order => {
            if (orderIds.includes(order.orderId)) {
                console.log(`Assigning Driver to Order: ${order.orderId}`);
                return { ...order, assignedDriver: driverId };
            }
            return order;
        }));
    };

    // Callback to handle unassigning drivers
    const handleUnassignDriver = async (orderId) => {
        try {
            await axios.put(`https://api.jatillforsvar.se/api/orders/${orderId}/assign-driver`, {
                assignedDriver: null
            });

            // Update parent state
            setOrders(prevOrders => prevOrders.map(order => {
                if (order.orderId === orderId) {
                    console.log(`Unassigning Driver from Order: ${order.orderId}`);
                    return { ...order, assignedDriver: null };
                }
                return order;
            }));

            toast.success(`Order unassigned successfully!`);
        } catch (error) {
            console.error("Error unassigning driver:", error);
            toast.error("Failed to unassign driver.");
        }
    };

    // Callback to handle status updates
    const handleUpdateStatus = (orderId, newStatus) => {
        console.log(`Updating Status for Order: ${orderId} to ${newStatus}`);

        setOrders(prevOrders => prevOrders.map(order => {
            if (order.orderId === orderId) {
                return { ...order, status: newStatus };
            }
            return order;
        }));
    };

    // Debugging: Monitor orders state changes
    useEffect(() => {
        console.log("Orders State Updated:", orders);
    }, [orders]);

    if (loading) {
        return <p>Loading data...</p>;
    }

    return (
        <div className="driver-assignment-container">
        <h2>Driver Assignment Dashboard</h2>
        <div className="orders-section">
            <OrdersList
                orders={orders}
                drivers={drivers}
                onAssignDriver={handleAssignDriver}
                onAssignDriverBulk={handleAssignDriverBulk}
                onUpdateStatus={handleUpdateStatus}
            />
        </div>
        <div className="calendars-section">
            <h3>Drivers' Calendars</h3>
            <DriverCalendar
                drivers={drivers}
                orders={orders}
                onAssignDriver={handleAssignDriver}
                onUnassignDriver={handleUnassignDriver}
            />
        </div>
        <ToastContainer />
    </div>
    );

};

export default DriverAssignmentCalendar;
