import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  Grid,
} from "@mui/material";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { Draggable } from "@fullcalendar/interaction";
import { getColorByStatus } from "../../utils/StatusColors";
import axios from 'axios';

const OrdersList = ({
  orders,
  drivers,
  onAssignDriver,
  onAssignDriverBulk,
  onUpdateStatus,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOrders, setFilteredOrders] = useState(orders);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const ordersRef = useRef(null);

  // Filter orders when search term changes
  useEffect(() => {
    const term = searchTerm.toLowerCase();
    setFilteredOrders(
      orders.filter((order) =>
        order.customerName.toLowerCase().includes(term)
      )
    );
  }, [orders, searchTerm]);

  useEffect(() => {
    if (ordersRef.current) {
      const draggable = new Draggable(ordersRef.current, {
        itemSelector: ".draggable-order",
        handle: ".drag-handle",
        eventData: (eventEl) => {
          const orderId = eventEl.dataset.orderId;
          const customerName = eventEl.dataset.customerName;
          const deliveryDate = eventEl.dataset.deliveryDate;
  
          if (!orderId) {
            console.error("Order ID is missing in eventData:", eventEl);
            return null;
          }
  
          return {
            id: orderId,
            title: customerName || "Untitled Order",
            start: deliveryDate || new Date().toISOString(),
            extendedProps: {
              orderId,
              customerName,
            },
          };
        },
      });
  
      return () => draggable.destroy();
    }
  }, [filteredOrders]);
  

  // Function to handle bulk driver assignment
  const handleBulkAssign = (driverId) => {
    if (!selectedOrders.length) {
      toast.warn("No orders selected for bulk assignment.");
      return;
    }

    onAssignDriverBulk(selectedOrders, driverId);
    toast.success(`Assigned driver to ${selectedOrders.length} orders.`);
    setSelectedOrders([]); // Clear selection
  };

  // Function to handle order selection
  const handleOrderSelect = (orderId) => {
    setSelectedOrders((prev) =>
      prev.includes(orderId)
        ? prev.filter((id) => id !== orderId)
        : [...prev, orderId]
    );
  };

  // Function to handle status change
  const handleStatusChange = async (newStatus, order) => {
    if (["Completed", "Cancelled"].includes(newStatus)) {
      const confirmChange = window.confirm(
        `Are you sure you want to mark order "${order.customerName}" as "${newStatus}"?`
      );
      if (!confirmChange) return;
    }

    try {
      await axios.put(
        `https://api.jatillforsvar.se/api/orders/${order.orderId}/update-status`,
        { status: newStatus }
      );

      toast.success(
        `Order "${order.customerName}" marked as "${newStatus}" successfully!`
      );

      // Call parent handler to reflect updated status in UI
      onUpdateStatus(order.orderId, newStatus);
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update order status. Please try again.");
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" align="center" gutterBottom>
        All Orders
      </Typography>

      {/* Search Field */}
      <TextField
        label="Search Orders"
        variant="outlined"
        fullWidth
        size="small"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ marginBottom: 2 }}
      />

      {/* Bulk Assign */}
      {selectedOrders.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
          <FormControl size="small" sx={{ width: 250 }}>
            <Select
              displayEmpty
              onChange={(e) => handleBulkAssign(e.target.value)}
            >
              <MenuItem value="">Select Driver for Bulk Assignment</MenuItem>
              {drivers.map((driver) => (
                <MenuItem key={driver._id} value={driver._id}>
                  {driver.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {/* Orders Columns */}
      <Grid container spacing={2} ref={ordersRef}>
        {["Pending", "In Progress", "Completed", "Cancelled"].map((status) => (
          <Grid item xs={12} sm={6} md={3} key={status}>
            <Card sx={{ borderRadius: 2, boxShadow: 2 }}>
              <CardHeader
                title={status}
                titleTypographyProps={{ variant: "h6", align: "center" }}
                sx={{
                  backgroundColor: getColorByStatus(status),
                  color: "#fff",
                }}
              />
              <CardContent sx={{ maxHeight: "350px", overflowY: "auto" }}>
                {filteredOrders
                  .filter((order) => order.status === status)
                  .map((order) => (
                    <Box
                      key={order.orderId}
                      className="draggable-order"
                      data-order-id={order.orderId}
                      data-customer-name={order.customerName}
                      data-delivery-date={order.deliveryDate}
                      sx={{
                        border: "1px solid #ddd",
                        borderRadius: 1,
                        padding: 1,
                        marginBottom: 1,
                        boxShadow: 1,
                        backgroundColor: "#fafafa",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {/* Drag Handle */}
                      <Box
                        className="drag-handle"
                        sx={{ cursor: "grab", fontSize: "0.8rem", color: "#888" }}
                      >
                        &#x2630; Drag
                      </Box>

                      {/* Order Info */}
                      <Typography variant="body2" fontWeight="bold">
                        {order.customerName}
                      </Typography>
                      <Typography variant="caption">
                        Delivery: {new Date(order.deliveryDate).toLocaleString()}
                      </Typography>

                      {/* Driver Assignment */}
                      <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                        <Select
                          value={order.assignedDriver || ""}
                          onChange={(e) =>
                            onAssignDriver(order.orderId, e.target.value)
                          }
                          displayEmpty
                        >
                          <MenuItem value="">Unassigned</MenuItem>
                          {drivers.map((driver) => (
                            <MenuItem key={driver._id} value={driver._id}>
                              {driver.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {/* Status Change */}
                      <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                        <Select
                          value={order.status}
                          onChange={(e) =>
                            handleStatusChange(e.target.value, order)
                          }
                        >
                          <MenuItem value="Pending">Pending</MenuItem>
                          <MenuItem value="In Progress">In Progress</MenuItem>
                          <MenuItem value="Completed">Completed</MenuItem>
                          <MenuItem value="Cancelled">Cancelled</MenuItem>
                        </Select>
                      </FormControl>

                      {/* Selection Checkbox */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedOrders.includes(order.orderId)}
                            onChange={() => handleOrderSelect(order.orderId)}
                          />
                        }
                        label="Select"
                        sx={{ marginTop: 1 }}
                      />
                    </Box>
                  ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

OrdersList.propTypes = {
  orders: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  onAssignDriver: PropTypes.func.isRequired,
  onAssignDriverBulk: PropTypes.func.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
};

export default OrdersList;
