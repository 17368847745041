import React, { useState, useEffect } from "react";
import { Container, Typography, Paper } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ClientForm from "../components/clientform/ClientForm";
import ModuleSelection from "../components/moduleselection/ModuleSelection";
import ClientTable from "../components/clienttable/ClientTable";
import PricelistModal from "../components/pricelistmodal/PricelistModal";


const Clients = () => {
  // State Variables
  const [clients, setClients] = useState([]);
  const [pricelists, setPricelists] = useState([]);
  const [modules, setModules] = useState([]);
  const [groupedModules, setGroupedModules] = useState({});
  const [selectedModules, setSelectedModules] = useState({});
  const [clientForm, setClientForm] = useState({
    namn: "",
    Orgnummer: "",
    Kontonamn: "",
    Postadress: "",
    Postnummer: "",
    Ort: "",
    Land: "Sverige",
    Telefonnummer: "",
    Epost: "",
    Saljare: "",
    Prislista: "",
    Kredittid: 15,
    Faktureringtid: 15
  });
  const [isClientEditMode, setIsClientEditMode] = useState(false);
  const [currentClientId, setCurrentClientId] = useState(null);
  const [modalClient, setModalClient] = useState(null);
  const [newPricelist, setNewPricelist] = useState(null);
  const [clientPricelist, setClientPricelist] = useState(null);

  // Fetch Clients, Pricelists, and Modules on Component Mount
  useEffect(() => {
    fetchClients();
    fetchPricelists();
    fetchModules();
  }, []);

  const groupByCategory = (modules) => {
    return modules.reduce((acc, module) => {
      if (!acc[module.category]) {
        acc[module.category] = [];
      }
      acc[module.category].push(module);
      return acc;
    }, {});
  };

  const deepMerge = (target, source) => {
    for (const key in source) {
      if (source[key] instanceof Object && key in target) {
        target[key] = deepMerge(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
    return target;
  };
  const transformPrices = (obj) => {
    if (obj && typeof obj === "object" && !Array.isArray(obj)) {
      const transformed = {};
      for (const key in obj) {
        const val = obj[key];
        if (typeof val === "number") {
          transformed[key] = {
            originalPrice: val,
            rebate: 0, // Default value
            kickback: 0, // Default value
            finalPrice: val,
          };
        } else if (typeof val === "object") {
          transformed[key] = transformPrices(val);
        }
      }
      return transformed;
    }
    return obj;
  };
  
  const recalculateFinalPrice = (originalPrice, rebate, kickback) => {
    const afterRebate = originalPrice * (1 - (rebate / 100));
    const afterKickback = afterRebate * (1 - (kickback / 100));
    return parseFloat(afterKickback.toFixed(2));
  };

  // Fetch Clients from Backend
  const fetchClients = async () => {
    try {
      const response = await fetch("https://api.jatillforsvar.se/api/clients");
      if (response.ok) {
        const data = await response.json();
        setClients(data);
      } else {
        console.error("Failed to fetch clients:", response.statusText);
        toast.error("Failed to fetch clients.");
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      toast.error("Error fetching clients.");
    }
  };

  // Fetch Pricelists from Backend
  const fetchPricelists = async () => {
    try {
      const response = await fetch("https://api.jatillforsvar.se/api/pricelists");
      if (response.ok) {
        const data = await response.json();
        setPricelists(data);
      } else {
        console.error("Failed to fetch pricelists:", response.statusText);
        toast.error("Failed to fetch pricelists.");
      }
    } catch (error) {
      console.error("Error fetching pricelists:", error);
      toast.error("Error fetching pricelists.");
    }
  };

  // Fetch Modules from Backend
  const fetchModules = async () => {
    try {
      const response = await fetch("https://api.jatillforsvar.se/api/pricelists");
      if (response.ok) {
        const data = await response.json();
        setModules(data);
        const grouped = groupByCategory(data);
        setGroupedModules(grouped);
      } else {
        console.error("Failed to fetch modules:", response.statusText);
        toast.error("Failed to fetch modules.");
      }
    } catch (error) {
      console.error("Error fetching modules:", error);
      toast.error("Error fetching modules.");
    }
  };

  const handleModuleSelectionChange = (category, moduleId) => {
    setSelectedModules(prevSelected => ({
      ...prevSelected,
      [category]: moduleId
    }));
  };

  const handleClientSubmit = async (e) => {
    e.preventDefault();
    const {
      namn, Orgnummer, Kontonamn, Postadress, Postnummer, Ort, Land,
      Telefonnummer, Epost, Saljare, Prislista, Kredittid, Faktureringtid
    } = clientForm;

    // Validate required fields
    if (
      !namn || !Orgnummer || !Kontonamn || !Postadress || !Postnummer || !Ort ||
      !Telefonnummer || !Epost || !Saljare || !Prislista || !Kredittid || !Faktureringtid
    ) {
      toast.error("Please fill in all required client fields.");
      return;
    }

    // Validate module selection
    const categories = Object.keys(groupedModules);
    for (let category of categories) {
      if (!selectedModules[category]) {
        toast.error(`Please select a module for category: ${category}`);
        return;
      }
    }

    try {
      // Fetch selected modules data
      const selectedModulesData = modules.filter(module =>
        Object.values(selectedModules).includes(module._id)
      );

      // Merge selected modules' prices
      const mergedPriceList = selectedModulesData.reduce((acc, module) => {
        return deepMerge(acc, module.prices);
      }, {});

      // Transform mergedPriceList
      const transformedPriceList = transformPrices(mergedPriceList);

      const pricelistObject = {
        priceListName: `Pricelist for ${namn}`,
        priceListItems: transformedPriceList
      };

      const method = isClientEditMode ? "PUT" : "POST";
      const url = isClientEditMode
        ? `https://api.jatillforsvar.se/api/clients/${currentClientId}`
        : "https://api.jatillforsvar.se/api/clients";

      const payload = {
        namn, Orgnummer, Kontonamn, Postadress, Postnummer, Ort,
        Land, Telefonnummer, Epost, Saljare, Prislista, Kredittid, Faktureringtid,
      };

      // Only attach pricelist if new client (for edit mode, handle separately if needed)
      if (!isClientEditMode) payload.pricelist = pricelistObject;

      const response = await fetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const newClient = await response.json();
        if (isClientEditMode) {
          setClients(prevClients =>
            prevClients.map(c => c._id === newClient._id ? newClient : c)
          );
          toast.success("Client updated successfully!");
        } else {
          setClients(prevClients => [...prevClients, newClient]);
          toast.success("Client added successfully with selected modules!");
        }

        // Reset form
        setClientForm({
          namn: "",
          Orgnummer: "",
          Kontonamn: "",
          Postadress: "",
          Postnummer: "",
          Ort: "",
          Land: "Sverige",
          Telefonnummer: "",
          Epost: "",
          Saljare: "",
          Prislista: "",
          Kredittid: 15,
          Faktureringtid: 15
        });
        setSelectedModules({});
        setIsClientEditMode(false);
        setCurrentClientId(null);
      } else {
        const errorData = await response.json();
        console.error("Failed to add/update client:", errorData);
        toast.error(`Failed to save client: ${errorData.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error submitting client form:", error);
      toast.error("An error occurred while submitting the form.");
    }
  };

  const handleClientEdit = (client) => {
    setClientForm({
      namn: client.namn || "",
      Orgnummer: client.Orgnummer || "",
      Kontonamn: client.Kontonamn || "",
      Postadress: client.Postadress || "",
      Postnummer: client.Postnummer || "",
      Ort: client.Ort || "",
      Land: client.Land || "Sverige",
      Telefonnummer: client.Telefonnummer || "",
      Epost: client.Epost || "",
      Saljare: client.Saljare || "",
      Prislista: client.Prislista || "",
      Kredittid: client.Kredittid || 15,
      Faktureringtid: client.Faktureringtid || 15
    });
    setIsClientEditMode(true);
    setCurrentClientId(client._id);
    // Optionally load selectedModules from client (if stored), else reset
    setSelectedModules({});
  };

  const handleCancelEdit = () => {
    setIsClientEditMode(false);
    setCurrentClientId(null);
    setClientForm({
      namn: "",
      Orgnummer: "",
      Kontonamn: "",
      Postadress: "",
      Postnummer: "",
      Ort: "",
      Land: "Sverige",
      Telefonnummer: "",
      Epost: "",
      Saljare: "",
      Prislista: "",
      Kredittid: 15,
      Faktureringtid: 15
    });
    setSelectedModules({});
  };

  const handleClientDelete = async (clientId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this client?");
    if (!confirmDelete) return;

    try {
      const response = await fetch(`https://api.jatillforsvar.se/api/clients/${clientId}`, {
        method: "DELETE",
      });

      if (response.ok) {
        const { message } = await response.json();
        setClients(prevClients => prevClients.filter(client => client._id !== clientId));
        toast.success(message);
      } else {
        const errorData = await response.json();
        console.error("Failed to delete client:", errorData);
        toast.error(`Failed to delete client: ${errorData.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error deleting client:", error);
      toast.error("An error occurred while deleting the client.");
    }
  };

  const openModal = async (client) => {
    console.log("Client passed to openModal:", client); // Debugging log
  
    if (!client || !client._id) {
      console.error("Invalid client object or missing ID.");
      toast.error("Client ID is missing or invalid.");
      return;
    }
  
    setModalClient(client);
    setNewPricelist(null);
    setClientPricelist(null);
  
    try {
      // Use client.id for the API request
      const response = await fetch(`https://api.jatillforsvar.se/api/clientPricelist/${client.id}`);
      if (response.ok) {
        const clientPL = await response.json();
        setClientPricelist(clientPL);
        setNewPricelist({
          ...clientPL,
          priceListItems: JSON.parse(JSON.stringify(clientPL.priceListItems)),
        });
      } else if (response.status === 404) {
        setClientPricelist(null);
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch client's pricelist:", errorData.message);
        toast.error("Failed to fetch client's pricelist.");
      }
    } catch (error) {
      console.error("Error fetching client's pricelist:", error);
      toast.error("Error fetching client's pricelist.");
    }
  };
  

  const closeModal = () => {
    setModalClient(null);
    setNewPricelist(null);
    setClientPricelist(null);
  };

  const saveClientPricelist = async () => {
    if (!modalClient || !newPricelist) {
      toast.error("No client or pricelist selected.");
      return;
    }

    try {
      const response = await fetch(`https://api.jatillforsvar.se/api/clientPricelist/${modalClient.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ priceListItems: newPricelist.priceListItems }),
      });

      if (response.ok) {
        toast.success("Pricelist updated successfully!");
        closeModal();
      } else {
        const errorData = await response.json();
        console.error("Failed to update client pricelist:", errorData.error);
        toast.error(`Failed to update pricelist: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Error saving client pricelist:", error);
      toast.error("An error occurred while saving the pricelist.");
    }
  };

  const handleNestedChange = (path, field, newValue) => {
    if (!newPricelist) return;
  
    const updated = { ...newPricelist };
  
    // Navigate to the correct path
    const traverse = (obj, keys) => {
      if (keys.length === 0) return obj;
      const [first, ...rest] = keys;
      if (!obj[first]) obj[first] = {};
      return traverse(obj[first], rest);
    };
  
    const container = traverse(updated.priceListItems, path.slice(0, -1));
    const lastKey = path[path.length - 1];
  
    if (!container[lastKey]) container[lastKey] = {};
  
    // Update the field
    container[lastKey][field] = parseFloat(newValue) || 0;
  
    // Recalculate final price
    const originalPrice = container[lastKey].originalPrice || 0;
    const rebate = container[lastKey].rebate || 0;
    const kickback = container[lastKey].kickback || 0;
    container[lastKey].finalPrice = parseFloat(
      ((originalPrice * (1 - rebate / 100)) * (1 - kickback / 100)).toFixed(2)
    );
  
    setNewPricelist(updated);
  };
  

  return (
    <Container maxWidth="lg" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        Clients
      </Typography>

      <Paper elevation={3} style={{ padding: '2rem', marginBottom: '2rem' }}>
        <ClientForm
          clientForm={clientForm}
          setClientForm={setClientForm}
          isClientEditMode={isClientEditMode}
          handleClientSubmit={handleClientSubmit}
          handleCancelEdit={handleCancelEdit}
        />

        {!isClientEditMode && (
          <ModuleSelection
            groupedModules={groupedModules}
            selectedModules={selectedModules}
            handleModuleSelectionChange={handleModuleSelectionChange}
          />
        )}
      </Paper>

      <ClientTable
        clients={clients}
        openModal={openModal}
        handleClientEdit={handleClientEdit}
        handleClientDelete={handleClientDelete}
      />

      <PricelistModal
        modalClient={modalClient}
        clientPricelist={clientPricelist}
        newPricelist={newPricelist}
        openModal={openModal}
        closeModal={closeModal}
        setNewPricelist={setNewPricelist}
        handleNestedChange={handleNestedChange}
        saveClientPricelist={saveClientPricelist}
      />

      <ToastContainer />
    </Container>
  );
};

export default Clients;
