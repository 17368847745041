// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Import Pages
import Clients from './pages/Clients';
import Pricelists from './pages/Pricelists';
import Orders from './pages/Orders';
import Drivers from './pages/Drivers';
import Articles from './pages/Articles';
import DriverAssignmentCalendar from './components/drivercalendar/DriverAssignmentCalendar';
import DriverView from './pages/DriverView';

// Import the Navigation component
import Navigation from './components/navigation/Navigation';
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});
function App() {
  function loadGoogleMapsScript(apiKey) {
    return new Promise((resolve, reject) => {
      if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
        // Script already loaded
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      script.onerror = (e) => reject(e);
      document.head.appendChild(script);
    });
  }

  const [isScriptLoaded, setIsScriptLoaded] = React.useState(false);

  React.useEffect(() => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    if (!apiKey) {
      console.error('Google Maps API key not found. Make sure you defined REACT_APP_GOOGLE_MAPS_API_KEY in .env file.');
      return;
    }

    loadGoogleMapsScript(apiKey)
      .then(() => {
        setIsScriptLoaded(true);
      })
      .catch(err => {
        console.error('Failed to load Google Maps script:', err);
      });
  }, []);

  if (!isScriptLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navigation />
        <Routes>
          <Route path="/clients" element={<Clients />} />
          <Route path="/pricelists" element={<Pricelists />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/drivers" element={<Drivers />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/tms-order" element={<DriverAssignmentCalendar />} />
          <Route path="/driver-view" element={<DriverView />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;

