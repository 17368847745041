import React from "react";
import { Typography, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";

const ModuleSelection = ({
  groupedModules,
  selectedModules,
  handleModuleSelectionChange
}) => {
  // Helper function to capitalize first letter
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Grid item xs={12}>
      <Typography variant="subtitle1" gutterBottom>
        Select Modules:
      </Typography>
      <Grid container spacing={2}>
        {Object.entries(groupedModules).map(([category, mods]) => (
          <Grid item xs={12} sm={6} md={4} key={category}>
            <FormControl component="fieldset">
              <FormLabel component="legend">{capitalizeFirstLetter(category)}</FormLabel>
              <RadioGroup
                aria-label={category}
                name={`module-${category}`}
                value={selectedModules[category] || ""}
                onChange={(e) => handleModuleSelectionChange(category, e.target.value)}
              >
                {mods.map(module => (
                  <FormControlLabel
                    key={module._id}
                    value={module._id}
                    control={<Radio />}
                    label={capitalizeFirstLetter(module.type)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ModuleSelection;
