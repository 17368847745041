import React from "react";
import { Typography, TextField, Button, Grid, MenuItem } from "@mui/material";
import { toast } from "react-toastify";

// Predefined values for dropdowns
const KREDITTID_VALUES = [15, 30, 60];
const FAKTURERINGTID_VALUES = [1, 15, 30, 60];

const ClientForm = ({
  clientForm,
  setClientForm,
  isClientEditMode,
  handleClientSubmit,
  handleCancelEdit
}) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClientForm(prevForm => ({
      ...prevForm,
      [name]: value
    }));
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {isClientEditMode ? "Edit Client" : "Add New Client"}
      </Typography>
      <form onSubmit={handleClientSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Namn"
              name="namn"
              value={clientForm.namn}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Orgnummer (xxxxxx-xxxx)"
              name="Orgnummer"
              value={clientForm.Orgnummer}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Kontonamn"
              name="Kontonamn"
              value={clientForm.Kontonamn}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Postadress"
              name="Postadress"
              value={clientForm.Postadress}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Postnummer"
              name="Postnummer"
              value={clientForm.Postnummer}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Ort"
              name="Ort"
              value={clientForm.Ort}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Land"
              name="Land"
              value={clientForm.Land}
              onChange={handleInputChange}
              fullWidth
              placeholder="Sverige"
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Telefonnummer"
              name="Telefonnummer"
              value={clientForm.Telefonnummer}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="E-post"
              name="Epost"
              value={clientForm.Epost}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Säljare"
              name="Saljare"
              value={clientForm.Saljare}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Prislista"
              name="Prislista"
              value={clientForm.Prislista}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              select
              label="Kredittid (days)"
              name="Kredittid"
              value={clientForm.Kredittid}
              onChange={handleInputChange}
              fullWidth
            >
              {KREDITTID_VALUES.map(val => (
                <MenuItem key={val} value={val}>{val}</MenuItem>
              ))}
            </TextField>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              required
              select
              label="Faktureringtid (days)"
              name="Faktureringtid"
              value={clientForm.Faktureringtid}
              onChange={handleInputChange}
              fullWidth
            >
              {FAKTURERINGTID_VALUES.map(val => (
                <MenuItem key={val} value={val}>{val}</MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginRight: '1rem' }}
            >
              {isClientEditMode ? "Update Client" : "Add Client"}
            </Button>
            {isClientEditMode && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancelEdit}
              >
                Cancel
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ClientForm;
