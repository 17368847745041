import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import axios from "axios";

const DriverView = () => {
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState("");
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [updatingOrderId, setUpdatingOrderId] = useState(null);

  // Fetch drivers on component mount
  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await axios.get("https://api.jatillforsvar.se/api/drivers");
        setDrivers(response.data);
      } catch (err) {
        console.error("Error fetching drivers:", err);
        setError("Failed to fetch drivers.");
      }
    };
    fetchDrivers();
  }, []);

  // Fetch orders when a driver is selected
  useEffect(() => {
    if (!selectedDriver) return;

    const fetchOrders = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `https://api.jatillforsvar.se/api/orders/driver/${selectedDriver}`
        );
        setOrders(response.data);
      } catch (err) {
        console.error("Error fetching orders:", err);
        setError("Failed to fetch orders for the selected driver.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [selectedDriver]);

  const handleDriverChange = (event) => {
    setSelectedDriver(event.target.value);
    setOrders([]); // Clear orders when a new driver is selected
  };

  const handleStatusChange = async (orderId, newStatus) => {
    setUpdatingOrderId(orderId);
    try {
      const response = await axios.put(
        `https://api.jatillforsvar.se/api/orders/${orderId}/update-status`,
        { status: newStatus }
      );
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.orderId === orderId ? { ...order, status: response.data.status } : order
        )
      );
    } catch (err) {
      console.error("Error updating order status:", err);
      setError("Failed to update the order status.");
    } finally {
      setUpdatingOrderId(null);
    }
  };

  return (
    <Box sx={{ p: 4, maxWidth: "900px", mx: "auto" }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontWeight: "bold", textAlign: "center", mb: 4 }}
      >
        Driver View
      </Typography>

      {/* Driver Selector */}
      <FormControl fullWidth sx={{ mb: 3 }}>
        <InputLabel id="driver-select-label">Select Driver</InputLabel>
        <Select
          labelId="driver-select-label"
          value={selectedDriver}
          onChange={handleDriverChange}
          sx={{
            backgroundColor: "#f9f9f9",
            borderRadius: "8px",
            boxShadow: "0px 2px 6px rgba(0,0,0,0.1)",
          }}
        >
          {drivers.map((driver) => (
            <MenuItem key={driver._id} value={driver._id}>
              {driver.name} ({driver.truckType})
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Display Loading, Error, or Orders */}
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Alert severity="error" sx={{ mt: 4 }}>
          {error}
        </Alert>
      )}

      {orders.length > 0 ? (
        <Box>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
              mb: 2,
              borderBottom: "2px solid #f0f0f0",
              pb: 1,
              color: "#333",
            }}
          >
            Orders Assigned to {drivers.find((d) => d._id === selectedDriver)?.name}
          </Typography>
          {orders.map((order) => (
            <Card
              key={order.orderId}
              sx={{
                mb: 3,
                borderRadius: "8px",
                border: "1px solid #e0e0e0",
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.05)",
                transition: "transform 0.2s",
                "&:hover": { transform: "scale(1.02)" },
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#444", mb: 1 }}
                >
                  {order.customerName}
                </Typography>
                <Typography variant="body2" sx={{ color: "#666" }}>
                  Pickup: {order.pickupLocation}
                </Typography>
                <Typography variant="body2" sx={{ color: "#666" }}>
                  Drop-off: {order.dropOffLocation}
                </Typography>
                <Typography variant="body2" sx={{ color: "#666" }}>
                  Delivery Date:{" "}
                  {new Date(order.deliveryDate).toLocaleDateString()}{" "}
                  {new Date(order.deliveryDate).toLocaleTimeString()}
                </Typography>
                <Typography variant="body2" sx={{ color: "#666" }}>
                  Duration: {order.duration} minutes
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: order.status === "Completed" ? "#28a745" : "#dc3545",
                    fontWeight: "bold",
                  }}
                >
                  Status: {order.status}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  Items:
                </Typography>
                <List>
                  {order.items.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={`Path: ${item.path.join(", ")}`}
                        secondary={`Price: $${item.originalPrice.toFixed(
                          2
                        )} → $${item.finalPrice.toFixed(2)}`}
                      />
                    </ListItem>
                  ))}
                </List>
                <Divider sx={{ my: 2 }} />
                <FormControl fullWidth>
                  <InputLabel id={`status-select-label-${order.orderId}`}>
                    Update Status
                  </InputLabel>
                  <Select
                    labelId={`status-select-label-${order.orderId}`}
                    value={order.status}
                    onChange={(e) => handleStatusChange(order.orderId, e.target.value)}
                    disabled={updatingOrderId === order.orderId}
                    sx={{
                      backgroundColor: "#f9f9f9",
                      borderRadius: "8px",
                      boxShadow: "0px 2px 6px rgba(0,0,0,0.1)",
                    }}
                  >
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="In Progress">In Progress</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                    <MenuItem value="Cancelled">Cancelled</MenuItem>
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          ))}
        </Box>
      ) : (
        !loading &&
        !error && (
          <Typography
            variant="body1"
            sx={{ textAlign: "center", color: "#999", mt: 4 }}
          >
            No orders assigned to the selected driver.
          </Typography>
        )
      )}
    </Box>
  );
};

export default DriverView;
