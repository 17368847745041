// src/utils/statusColors.js
export const getColorByStatus = (status, isBackground = false) => {
  switch (status) {
      case 'Pending':
          return isBackground ? '#fdf7e3' : '#ffc107'; // Light yellow for background, darker for border
      case 'In Progress':
          return isBackground ? '#e8f5e9' : '#28a745'; // Light green for background, darker for border
      case 'Completed':
          return isBackground ? '#e3f2fd' : '#007bff'; // Light blue for background, darker for border
      case 'Cancelled':
          return isBackground ? '#f8d7da' : '#dc3545'; // Light red for background, darker for border
      default:
          return isBackground ? '#f9f9f9' : '#6c757d'; // Light gray for background, darker for border
  }
};

  // src/utils/statusColors.js

const statusColors = {
    'Pending': '#f0ad4e',      // Amber
    'In Progress': '#28a745',  // Blue
    'Completed': '#5cb85c',    // Green
    'Cancelled': '#d9534f'     // Red
};

export default statusColors;
