import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import "./PriceListModal.css";

const RenderNestedPrices = ({ data, path = [], handleNestedChange }) => {
  if (!data || typeof data !== "object") return null;

  if (typeof data.originalPrice === "number") {
    const rebate = data.rebate || 0;
    const kickback = data.kickback || 0;
    const rebateAmount = (data.originalPrice * rebate) / 100;
    const priceAfterRebate = (data.originalPrice - rebateAmount).toFixed(2);
    const finalPrice = (priceAfterRebate * (1 - kickback / 100)).toFixed(2);

    return (
      <TableRow className="table-row">
        <TableCell>{path[path.length - 1]}</TableCell>
        <TableCell className="table-cell-highlight">
          {data.originalPrice.toFixed(2)}
        </TableCell>
        <TableCell>
          <input
            type="number"
            value={rebate}
            onChange={(e) => handleNestedChange(path, "rebate", e.target.value)}
            placeholder="0"
            min="0"
            max="100"
            className="input-field"
          />
        </TableCell>
        <TableCell>{priceAfterRebate}</TableCell>
        <TableCell>
          <input
            type="number"
            value={kickback}
            onChange={(e) =>
              handleNestedChange(path, "kickback", e.target.value)
            }
            placeholder="0"
            min="0"
            max="100"
            className="input-field"
          />
        </TableCell>
        <TableCell className="final-price">{finalPrice}</TableCell>
      </TableRow>
    );
  }

  const keys = Object.keys(data);

  return (
    <>
      <Typography className="category-header">{path[path.length - 1]}</Typography>
      {keys.map((key) => (
        <RenderNestedPrices
          key={key}
          data={data[key]}
          path={[...path, key]}
          handleNestedChange={handleNestedChange}
        />
      ))}
    </>
  );
};

const PricelistModal = ({
  modalClient,
  clientPricelist,
  newPricelist,
  closeModal,
  setNewPricelist,
  handleNestedChange,
  saveClientPricelist,
}) => (
  <Dialog open={Boolean(modalClient)} onClose={closeModal} maxWidth="lg" fullWidth>
    <DialogTitle>
      {modalClient ? `Pricelists for ${modalClient.namn}` : ""}
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{ position: "absolute", right: 8, top: 8, color: "#555" }}
      >
        <Close />
      </IconButton>
    </DialogTitle>
    <DialogContent dividers>
      {clientPricelist && !newPricelist ? (
        <div className="pricelist-view">
          <Typography variant="h6" gutterBottom>
            {clientPricelist.priceListName}
          </Typography>
          <TableContainer component={Paper} className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Article</TableCell>
                  <TableCell>Original Price (SEK)</TableCell>
                  <TableCell>Rebate (%)</TableCell>
                  <TableCell>Price after Rebate (SEK)</TableCell>
                  <TableCell>Kickback (%)</TableCell>
                  <TableCell>Final Price (SEK)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <RenderNestedPrices
                  data={clientPricelist.priceListItems}
                  path={[]}
                  handleNestedChange={handleNestedChange}
                />
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            className="edit-button"
            onClick={() =>
              setNewPricelist({
                ...clientPricelist,
                priceListItems: JSON.parse(
                  JSON.stringify(clientPricelist.priceListItems)
                ),
              })
            }
          >
            Edit Pricelist
          </Button>
        </div>
      ) : newPricelist ? (
        <div className="pricelist-edit">
          <Typography variant="h6" gutterBottom>
            Edit Pricelist
          </Typography>
          <TableContainer component={Paper} className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Article</TableCell>
                  <TableCell>Original Price (SEK)</TableCell>
                  <TableCell>Rebate (%)</TableCell>
                  <TableCell>Price after Rebate (SEK)</TableCell>
                  <TableCell>Kickback (%)</TableCell>
                  <TableCell>Final Price (SEK)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <RenderNestedPrices
                  data={newPricelist.priceListItems}
                  path={[]}
                  handleNestedChange={handleNestedChange}
                />
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            className="save-button"
            onClick={saveClientPricelist}
          >
            Save Pricelist
          </Button>
        </div>
      ) : (
        <Typography className="no-pricelist">
          No pricelist found for this client. Please create one by selecting
          modules.
        </Typography>
      )}
    </DialogContent>
    <DialogActions></DialogActions>
  </Dialog>
);

export default PricelistModal;
