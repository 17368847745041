// DriverCalendar.js

import React, { useState, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import axios from 'axios';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import './styles/DriverCalendar.css';
import statusColors from '../../utils/StatusColors';

Modal.setAppElement('#root'); // Important for accessibility

const DriverCalendar = ({ drivers, orders, onAssignDriver, onUnassignDriver }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const calendarRef = useRef(null);

    // Filter valid and active orders (exclude Completed and Cancelled)
    const activeOrders = orders.filter(order =>
        order &&
        order.orderId &&
        order.deliveryDate &&
        (order.status === 'Pending' || order.status === 'In Progress')
    );

    // Map drivers to resources for FullCalendar
    const resources = drivers.map(driver => ({
        id: driver._id,
        title: driver.name
    }));

    // Map orders to events for FullCalendar with color coding
    const events = activeOrders.map(order => ({
        id: order.orderId,
        title: order.customerName,
        start: new Date(order.deliveryDate),
        end: new Date(new Date(order.deliveryDate).getTime() + (order.duration || 60) * 60 * 1000), // Duration in ms
        resourceId: order.assignedDriver,
        backgroundColor: statusColors[order.status],
        borderColor: statusColors[order.status],
        textColor: '#fff', // Ensure text is readable
        extendedProps: {
            pickupLocation: order.pickupLocation,
            dropOffLocation: order.dropOffLocation,
            contactInfo: order.contactInfo,
            shipmentDetails: order.shipmentDetails,
            orderId: order.orderId,
            status: order.status
        }
    }));

    // Handler for receiving external events (assigning driver via drag-and-drop)
    const handleEventReceive = async (info) => {
        const { orderId } = info.event.extendedProps || {};

        if (!orderId) {
            toast.error("Order ID is missing.");
            info.revert();
            return;
        }

        const order = orders.find(o => o.orderId === orderId);

        if (!order) {
            toast.error("Order not found.");
            info.revert();
            return;
        }

        const newDeliveryDate = info.event.start;
        const duration = 120; // Fixed duration of 2 hours
        const assignedDriver = info.event.getResources()[0]?.id; // Correctly retrieve the new driver ID

        if (!newDeliveryDate || !assignedDriver) {
            toast.error("Delivery date or driver is missing.");
            info.revert();
            return;
        }

        console.log(`Assigning Order ID: ${orderId} to Driver ID: ${assignedDriver} at ${newDeliveryDate} for ${duration} minutes.`);

        try {
            // API call to assign driver and update delivery date & duration
            const response = await axios.put(`https://api.jatillforsvar.se/api/orders/${orderId}/assign-driver`, {
                assignedDriver, // Driver's ID
                deliveryDate: newDeliveryDate, // New delivery date
                duration // Fixed duration
            });

            const updatedOrder = response.data;

            // Update state via callback
            onAssignDriver(
                orderId,
                updatedOrder.assignedDriver ? updatedOrder.assignedDriver : null,
                updatedOrder.deliveryDate,
                updatedOrder.duration
            );

            // Notify user
            const driverName = drivers.find(d => d._id === assignedDriver)?.name || "Unknown Driver";
            toast.success(`Order "${order.customerName}" assigned to driver "${driverName}" at ${newDeliveryDate.toLocaleString()} for ${duration} minutes.`);
        } catch (error) {
            console.error("Error assigning driver via drag-and-drop:", error);
            toast.error("Failed to assign driver. Please try again.");
            info.revert();
        }
    };

    // Handler for dragging and dropping events to reschedule or reassign drivers
    const handleEventDrop = async (info) => {
        const { orderId } = info.event.extendedProps || {};

        if (!orderId) {
            toast.error("Order ID is missing.");
            info.revert();
            return;
        }

        const order = orders.find(o => o.orderId === orderId);

        if (!order) {
            toast.error("Order not found.");
            info.revert();
            return;
        }

        const newDeliveryDate = info.event.start;
        const newEndDate = info.event.end;
        const duration = newEndDate ? (newEndDate - newDeliveryDate) / (1000 * 60) : (order.duration || 120); // Duration in minutes
        const assignedDriver = info.event.getResources()[0]?.id; // Correctly retrieve the new driver ID

        if (!newDeliveryDate || !assignedDriver) {
            toast.error("Delivery date or driver is missing.");
            info.revert();
            return;
        }

        console.log(`Rescheduling Order ID: ${orderId} to Driver ID: ${assignedDriver} at ${newDeliveryDate} for ${duration} minutes.`);

        try {
            // API call to update driver, delivery date, and duration
            const response = await axios.put(`https://api.jatillforsvar.se/api/orders/${orderId}/assign-driver`, {
                assignedDriver,
                deliveryDate: newDeliveryDate,
                duration
            });

            const updatedOrder = response.data;

            // Update state via callback
            onAssignDriver(
                orderId,
                updatedOrder.assignedDriver, // Driver's ID or null
                updatedOrder.deliveryDate,
                updatedOrder.duration
            );

            // Notify user
            const driverName = drivers.find(d => d._id === assignedDriver)?.name || "Unknown Driver";
            toast.success(`Order "${order.customerName}" rescheduled to ${newDeliveryDate.toLocaleString()} for ${updatedOrder.duration} minutes with driver "${driverName}".`);
        } catch (error) {
            console.error("Error updating delivery date via drag-and-drop:", error);
            toast.error("Failed to reschedule order. Please try again.");
            info.revert();
        }
    };

    // Handler for resizing events to change order duration
    const handleEventResize = async (info) => {
        const { orderId } = info.event.extendedProps || {};

        if (!orderId) {
            toast.error("Order ID is missing.");
            info.revert();
            return;
        }

        const order = orders.find(o => o.orderId === orderId);

        if (!order) {
            toast.error("Order not found.");
            info.revert();
            return;
        }

        const newEnd = info.event.end;
        const newStart = info.event.start;
        const newDuration = newEnd ? (newEnd - newStart) / (1000 * 60) : (order.duration || 120); // Duration in minutes

        if (newDuration < 30 || newDuration > 480) {
            toast.error("Duration must be between 30 and 480 minutes.");
            info.revert();
            return;
        }

        console.log(`Updating duration for Order ID: ${orderId} to ${newDuration} minutes.`);

        try {
            // API call to update duration
            const response = await axios.put(`https://api.jatillforsvar.se/api/orders/${orderId}/assign-driver`, {
                duration: newDuration
            });

            const updatedOrder = response.data;

            // Update state via callback
            onAssignDriver(
                orderId,
                updatedOrder.assignedDriver ? updatedOrder.assignedDriver : null,
                updatedOrder.deliveryDate,
                updatedOrder.duration
            );

            toast.success(`Order duration updated to ${updatedOrder.duration} minutes.`);
        } catch (error) {
            console.error("Error updating duration:", error);
            toast.error("Failed to update duration. Please try again.");
            info.revert();
        }
    };

    // Handler for clicking on events to view details
    const handleEventClick = (info) => {
        const { orderId } = info.event.extendedProps || {};

        if (!orderId) {
            toast.error("Order details are missing.");
            return;
        }

        const order = orders.find(o => o.orderId === orderId);

        if (!order) {
            toast.error("Order not found.");
            return;
        }

        const driver = drivers.find(d => d._id === order.assignedDriver);

        setSelectedOrder({
            orderId,
            customerName: order.customerName,
            pickupLocation: order.pickupLocation,
            dropOffLocation: order.dropOffLocation,
            contactInfo: order.contactInfo,
            shipmentDetails: order.shipmentDetails,
            deliveryDate: order.deliveryDate,
            assignedDriver: driver ? driver.name : "Unassigned",
            status: order.status
        });
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedOrder(null);
    };

    return (
        <div className="drivers-calendar">
            <h4>Drivers' Calendar</h4>
            <FullCalendar
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                plugins={[resourceTimeGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="resourceTimeGridDay"
                resources={resources}
                events={events}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'resourceTimeGridDay,resourceTimeGridWeek'
                }}
                slotMinTime="06:00:00"
                slotMaxTime="22:00:00"
                height="auto"
                droppable={true} // Enable dropping external events
                editable={true} // Enable dragging and resizing
                eventResizableFromStart={true} // Allow resizing from the start
                eventResize={handleEventResize} // Handle event resize
                eventReceive={handleEventReceive} // Handle event receive (external drop)
                eventClick={handleEventClick} // Handle event click
                eventDrop={handleEventDrop} // Handle event drag-and-drop
                ref={calendarRef}
            />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Order Details"
                className="modalCalendarOrder"
                overlayClassName="overlay"
            >
                <h2>Order Details</h2>
                <button onClick={closeModal} className="close-button">Close</button>
                {selectedOrder && (
                    <div>
                        <p><strong>Order ID:</strong> {selectedOrder.orderId}</p>
                        <p><strong>Customer Name:</strong> {selectedOrder.customerName}</p>
                        <p><strong>Pickup Location:</strong> {selectedOrder.pickupLocation}</p>
                        <p><strong>Drop-off Location:</strong> {selectedOrder.dropOffLocation}</p>
                        <p><strong>Contact Info:</strong> {selectedOrder.contactInfo}</p>
                        <p><strong>Shipment Details:</strong> {selectedOrder.shipmentDetails}</p>
                        <p><strong>Delivery Date:</strong> {new Date(selectedOrder.deliveryDate).toLocaleString()}</p>
                        <p><strong>Assigned Driver:</strong> {selectedOrder.assignedDriver}</p>
                        <p><strong>Status:</strong> <span style={{ color: statusColors[selectedOrder.status], fontWeight: 'bold' }}>{selectedOrder.status}</span></p>
                    </div>
                )}
            </Modal>
        </div>
    );   
};


export default DriverCalendar;
