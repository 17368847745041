import React from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

const ClientTable = ({ clients, openModal, handleClientEdit, handleClientDelete }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Namn</TableCell>
            <TableCell>Telefonnummer</TableCell>
            <TableCell>Orgnummer</TableCell>
            <TableCell>Kontonamn</TableCell>
            <TableCell>Ort</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clients.map(client => (
            <TableRow key={client._id}>
              <TableCell>{client.namn}</TableCell>
              <TableCell>{client.Telefonnummer}</TableCell>
              <TableCell>{client.Orgnummer}</TableCell>
              <TableCell>{client.Kontonamn}</TableCell>
              <TableCell>{client.Ort}</TableCell>
              <TableCell align="center">
                <Tooltip title="Manage Pricelists">
                  <IconButton color="primary" onClick={() => openModal(client)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit Client">
                  <IconButton color="secondary" onClick={() => handleClientEdit(client)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Client">
                  <IconButton color="error" onClick={() => handleClientDelete(client.id)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
          {clients.length === 0 && (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No clients found.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClientTable;
