import React, { useState, useEffect } from "react";
import {
    Container,
    Typography,
    TextField,
    Button,
    Grid,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Tooltip,
    Box,
} from "@mui/material";
import { Delete, Add, ExpandMore, ChevronRight } from "@mui/icons-material";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddressInput from "./AdressInput"; // Ensure the path is correct

// Helper function to capitalize first letter
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

// Recursive component to display and edit nested prices
const RenderNestedPrices = ({ data, path = [], handleNestedChange }) => {
    if (!data || typeof data !== "object") {
        return null;
    }

    // Leaf node: has originalPrice
    if (typeof data.originalPrice === "number") {
        // Calculate Price after Rebate and Kickback on Price after Rebate
        const rebateAmount = data.rebate ? (data.originalPrice * data.rebate) / 100 : 0;
        const priceAfterRebate = parseFloat((data.originalPrice - rebateAmount).toFixed(2));
        const kickbackPercentage = data.kickback || 0;
        const finalPrice = data.finalPrice !== undefined ? data.finalPrice : parseFloat((priceAfterRebate * (1 - kickbackPercentage / 100)).toFixed(2));

        return (
            <TableRow>
                <TableCell>{path[path.length - 1]}</TableCell>
                <TableCell>{data.originalPrice.toFixed(2)}</TableCell>
                <TableCell>
                    <TextField
                        type="number"
                        value={data.rebate}
                        onChange={(e) => handleNestedChange(path, "rebate", e.target.value)}
                        placeholder="0"
                        inputProps={{ min: 0, max: 100 }}
                        variant="standard"
                        fullWidth
                    />
                </TableCell>
                <TableCell>{priceAfterRebate.toFixed(2)}</TableCell>
                <TableCell>
                    <TextField
                        type="number"
                        value={kickbackPercentage}
                        onChange={(e) => handleNestedChange(path, "kickback", e.target.value)}
                        placeholder="0"
                        inputProps={{ min: 0, max: 100 }}
                        variant="standard"
                        fullWidth
                    />
                </TableCell>
                <TableCell>{finalPrice.toFixed(2)}</TableCell>
                <TableCell align="center">
                    <Tooltip title="Remove Item">
                        <IconButton onClick={() => handleNestedChange(path, "remove", null)}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
    }

    const keys = Object.keys(data);
    const allLeaf = keys.every((k) => data[k] && typeof data[k].originalPrice === "number");

    if (allLeaf) {
        return (
            <React.Fragment>
                {path.length > 0 && (
                    <TableRow>
                        <TableCell colSpan="7" style={{ backgroundColor: "#f5f5f5", fontWeight: "bold" }}>
                            {path[path.length - 1]}
                        </TableCell>
                    </TableRow>
                )}
                {keys.map((k) => (
                    <RenderNestedPrices key={k} data={data[k]} path={[...path, k]} handleNestedChange={handleNestedChange} />
                ))}
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                {path.length > 0 && (
                    <TableRow>
                        <TableCell colSpan="7" style={{ backgroundColor: "#f5f5f5", fontWeight: "bold" }}>
                            {path[path.length - 1]}
                        </TableCell>
                    </TableRow>
                )}
                {keys.map((k) => (
                    <RenderNestedPrices key={k} data={data[k]} path={[...path, k]} handleNestedChange={handleNestedChange} />
                ))}
            </React.Fragment>
        );
    }
};

const Orders = () => {
    // State Variables
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [clientPricelist, setClientPricelist] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [orderDetails, setOrderDetails] = useState({
        customerName: "",
        pickupLocation: "",
        dropOffLocation: "",
        contactInfo: "",
        shipmentDetails: "",
        deliveryDate: "",
        duration: 120, // Default duration in minutes
    });

    const [expandedCategories, setExpandedCategories] = useState([]);

    useEffect(() => {
        fetchClients();
    }, []);

    // Fetch Clients from Backend
    // Fetch Clients from Backend
    const fetchClients = async () => {
        try {
            const response = await fetch("https://api.jatillforsvar.se/api/clients");
            if (!response.ok) {
                throw new Error("Failed to fetch clients");
            }
            const data = await response.json();
            console.log("Fetched Clients:", data); // Add this line
            setClients(data);
        } catch (error) {
            console.error("Error fetching clients:", error);
            toast.error("Error fetching clients.");
        }
    };


    // Fetch Client Pricelist from Backend
    const fetchClientPricelist = async (clientId) => {
        try {
            const response = await fetch(`https://api.jatillforsvar.se/api/clientPricelist/${clientId}`);
            if (!response.ok) {
                throw new Error("Failed to fetch client pricelist");
            }
            const data = await response.json();
            setClientPricelist(data.priceListItems || {});
        } catch (error) {
            console.error("Error fetching client pricelist:", error);
            toast.error("Error fetching client pricelist.");
        }
    };

    // Handle Client Selection Change
    const handleClientChange = (selectedMongoId) => {
        const client = clients.find((c) => c._id === selectedMongoId);
        setSelectedClient(client);

        if (client && client.id) { // Use client.id instead of client.clientId
            fetchClientPricelist(client.id);
            // Set customerName based on selected client
            setOrderDetails((prevDetails) => ({
                ...prevDetails,
                customerName: client.namn,
            }));
        } else {
            // Handle cases where client or id might be undefined
            setClientPricelist(null);
            setOrderDetails((prevDetails) => ({
                ...prevDetails,
                customerName: "",
            }));
            toast.error("Selected client does not have a valid clientId.");
        }
    };


    // Handle Item Selection (Add to Order)
    const handleItemSelect = (path, item) => {
        const formattedItem = {
            path, // Keep 'path' as an array of strings
            originalPrice: item.originalPrice,
            rebate: item.rebate || 0,
            kickback: item.kickback || 0,
            finalPrice: item.finalPrice,
        };

        // Prevent adding duplicate items based on 'path'
        if (!selectedItems.some((selected) => selected.path.join(".") === path.join("."))) {
            setSelectedItems((prevItems) => [...prevItems, formattedItem]);
            toast.success(`Added "${path.join(" > ")}" to the order.`);
        } else {
            toast.info(`"${path.join(" > ")}" is already in the order.`);
        }
    };

    // Handle Item Removal (Remove from Order)
    const handleItemRemove = (path) => {
        setSelectedItems((prevItems) => prevItems.filter((item) => item.path.join(".") !== path.join(".")));
        toast.info(`Removed "${path.join(" > ")}" from the order.`);
    };

    // Handle Form Submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form before submission
        if (selectedItems.length === 0) {
            toast.error("Please select at least one item.");
            return;
        }

        if (!orderDetails.duration || orderDetails.duration < 30 || orderDetails.duration > 480) {
            toast.error("Duration must be between 30 and 480 minutes.");
            return;
        }

        // Prepare the data to be sent
        const payload = {
            ...orderDetails,
            items: selectedItems,
        };

        console.log("Submitting order:", payload); // Debugging line

        try {
            const response = await fetch("https://api.jatillforsvar.se/api/orders", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || "Failed to submit order");
            }

            const data = await response.json();
            console.log("Order submitted successfully:", data);
            toast.success("Order submitted successfully!");
            // Optionally, reset the form or redirect the user
            setOrderDetails({
                customerName: selectedClient ? selectedClient.namn : "",
                pickupLocation: "",
                dropOffLocation: "",
                contactInfo: "",
                shipmentDetails: "",
                deliveryDate: "",
                duration: 120, // Reset to default
            });
            setSelectedItems([]);
            setExpandedCategories([]); // Optionally collapse all categories after submission
        } catch (error) {
            console.error("Error submitting order:", error.message);
            toast.error(`Error submitting order: ${error.message}`);
        }
    };

    // Toggle Category Expansion
    const toggleCategory = (categoryKey) => {
        setExpandedCategories((prevExpanded) => {
            if (prevExpanded.includes(categoryKey)) {
                // If already expanded, collapse it
                return prevExpanded.filter((key) => key !== categoryKey);
            } else {
                // If collapsed, expand it
                return [...prevExpanded, categoryKey];
            }
        });
    };

    // Handle changes in nested price items
    const handleNestedChange = (path, field, newValue) => {
        if (!clientPricelist) return;

        const updatedPricelist = { ...clientPricelist };

        const traverse = (obj, keys) => {
            if (keys.length === 0) return obj;
            const [first, ...rest] = keys;
            if (!obj[first]) obj[first] = {};
            obj[first] = { ...obj[first] };
            return traverse(obj[first], rest);
        };

        const container = traverse(updatedPricelist, path.slice(0, -1));
        const lastKey = path[path.length - 1];

        const priceObj = { ...container[lastKey] };

        if (field === "remove") {
            // Handle item removal
            delete container[lastKey];
        } else {
            // Update rebate or kickback
            if (field === "rebate" || field === "kickback") {
                priceObj[field] = newValue !== "" ? parseFloat(newValue) : 0;
            }

            // Recalculate final price
            const rebate = priceObj.rebate || 0;
            const kickback = priceObj.kickback || 0;
            priceObj.finalPrice = recalculateFinalPrice(priceObj.originalPrice, rebate, kickback);

            // Update the price object
            container[lastKey] = priceObj;
        }

        console.log("Updating path:", path, "Field:", field, "New Value:", newValue);
        console.log("Updated Pricelist:", updatedPricelist);

        setClientPricelist(updatedPricelist);

        // Additionally, update selectedItems if necessary
        if (field === "rebate" || field === "kickback") {
            setSelectedItems((prevItems) =>
                prevItems.map((item) =>
                    item.path.join(".") === path.join(".")
                        ? { ...item, [field]: priceObj[field], finalPrice: priceObj.finalPrice }
                        : item
                )
            );
        } else if (field === "remove") {
            handleItemRemove(path);
        }
    };

    // Calculate and format total price
    const totalPrice = selectedItems.reduce((total, item) => {
        return total + (item.finalPrice || 0);
    }, 0);
    const formattedTotalPrice = totalPrice.toFixed(2);

    // Render Pricelist Items
    const renderPricelist = (data, path = []) => {
        if (!data || typeof data !== "object") {
            return null; // Skip invalid data
        }

        return Object.entries(data).map(([key, value]) => {
            const currentPath = path.concat(key).join(".");
            const isExpanded = expandedCategories.includes(currentPath);

            // If `value` is null or undefined, skip it
            if (!value) {
                return null;
            }

            // If `value` contains pricing details
            if (
                value.originalPrice !== undefined &&
                typeof value.originalPrice === "number"
            ) {
                return (
                    <Box key={currentPath} display="flex" alignItems="center" justifyContent="space-between" px={2} py={1}>
                        <Box>
                            <Typography variant="body1">{key}</Typography>
                            <Typography variant="body2" color="textSecondary">
                                {value.originalPrice} SEK {value.rebate ? `| Rebate: ${value.rebate}%` : ""} {value.kickback ? `| Kickback: ${value.kickback}%` : "No kickback"} {value.finalPrice ? `| Final Price: ${value.finalPrice} SEK` : ""}
                            </Typography>
                        </Box>
                        <Tooltip title="Add to Order">
                            <IconButton color="primary" onClick={() => handleItemSelect(path.concat(key), value)}>
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </Box>
                );
            }

            // If `value` is a nested object (e.g., category or subcategory)
            if (typeof value === "object") {
                return (
                    <Box key={currentPath} borderLeft={1} borderColor="grey.300" ml={2} mt={1} mb={1}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" px={2} py={1} onClick={() => toggleCategory(currentPath)} style={{ cursor: "pointer" }}>
                            <Typography variant="h6">{capitalizeFirstLetter(key)}</Typography>
                            <IconButton size="small">
                                {isExpanded ? <ExpandMore /> : <ChevronRight />}
                            </IconButton>
                        </Box>
                        {isExpanded && renderPricelist(value, path.concat(key))}
                    </Box>
                );
            }

            // Skip unexpected data types
            return null;
        });
    };

    // Function to recalculate final price
    const recalculateFinalPrice = (originalPrice, rebate, kickback) => {
        const afterRebate = originalPrice * (1 - (rebate / 100));
        const afterKickback = afterRebate * (1 - (kickback / 100));
        return parseFloat(afterKickback.toFixed(2));
    };

    return (
        <Container maxWidth="md" style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            <Typography variant="h4" gutterBottom>
                Create an Order
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    {/* Select Client */}
                    <Grid item xs={12}>
                        <FormControl fullWidth required>
                            <InputLabel id="client-select-label">Select Client</InputLabel>
                            <Select
                                labelId="client-select-label"
                                id="client-select"
                                value={selectedClient ? selectedClient._id : ""}
                                label="Select Client"
                                onChange={(e) => handleClientChange(e.target.value)}
                            >
                                <MenuItem value="">
                                    <em>-- Select Client --</em>
                                </MenuItem>
                                {clients.map((client) => (
                                    <MenuItem key={client._id} value={client._id}>
                                        {client.namn}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Pricelist */}
                    {clientPricelist && selectedClient && (
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                {selectedClient.namn}'s Pricelist
                            </Typography>
                            <Paper elevation={2} style={{ padding: "1rem", maxHeight: "300px", overflowY: "auto" }}>
                                {renderPricelist(clientPricelist)}
                            </Paper>
                        </Grid>
                    )}

                    {/* Selected Items */}
                    {selectedItems.length > 0 && (
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                Selected Items
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Article</TableCell>
                                            <TableCell>Original Price (SEK)</TableCell>
                                            <TableCell>Rebate (%)</TableCell>
                                            <TableCell>Price after Rebate (SEK)</TableCell>
                                            <TableCell>Kickback (%)</TableCell>
                                            <TableCell>Final Price (SEK)</TableCell>
                                            <TableCell align="center">Remove</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedItems.map((item) => (
                                            <RenderNestedPrices
                                                key={item.path.join(".")}
                                                data={item}
                                                path={item.path}
                                                handleNestedChange={handleNestedChange}
                                            />
                                        ))}
                                        <TableRow>
                                            <TableCell colSpan={5} align="right">
                                                <Typography variant="h6">Total:</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6">{formattedTotalPrice} SEK</Typography>
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}

                    {/* Pickup Location */}
                    <Grid item xs={12}>
                        <AddressInput
                            label="Pickup Location"
                            value={orderDetails.pickupLocation}
                            onSelect={(suggestion) =>
                                setOrderDetails({ ...orderDetails, pickupLocation: suggestion })
                            }
                        />
                    </Grid>

                    {/* Drop-off Location */}
                    <Grid item xs={12}>
                        <AddressInput
                            label="Drop-off Location"
                            value={orderDetails.dropOffLocation}
                            onSelect={(suggestion) =>
                                setOrderDetails({ ...orderDetails, dropOffLocation: suggestion })
                            }
                        />
                    </Grid>

                    {/* Contact Information */}
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Contact Information"
                            name="contactInfo"
                            value={orderDetails.contactInfo}
                            onChange={(e) =>
                                setOrderDetails({ ...orderDetails, contactInfo: e.target.value })
                            }
                            fullWidth
                        />
                    </Grid>

                    {/* Shipment Details */}
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Shipment Details"
                            name="shipmentDetails"
                            value={orderDetails.shipmentDetails}
                            onChange={(e) =>
                                setOrderDetails({ ...orderDetails, shipmentDetails: e.target.value })
                            }
                            fullWidth
                        />
                    </Grid>

                    {/* Preferred Delivery Date */}
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Preferred Delivery Date"
                            name="deliveryDate"
                            type="date"
                            value={orderDetails.deliveryDate}
                            onChange={(e) =>
                                setOrderDetails({ ...orderDetails, deliveryDate: e.target.value })
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>

                    {/* Duration */}
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Duration (minutes)"
                            name="duration"
                            type="number"
                            value={orderDetails.duration}
                            onChange={(e) =>
                                setOrderDetails({ ...orderDetails, duration: Number(e.target.value) })
                            }
                            inputProps={{ min: 30, max: 480 }}
                            fullWidth
                        />
                    </Grid>

                    {/* Submit Button */}
                    <Grid item xs={12} style={{ textAlign: "right" }}>
                        <Button variant="contained" color="primary" type="submit">
                            Submit Order
                        </Button>
                    </Grid>
                </Grid>
            </form>

            {/* Toast Notifications */}
            <ToastContainer />
        </Container>
    );
};

export default Orders;
