import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { 
  Container, 
  Typography, 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField 
} from '@mui/material';

function Pricelists() {
  const [pricelists, setPricelists] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', clientId: '', priceListItems: {} });

  useEffect(() => {
    fetchPricelists();
  }, []);

  const fetchPricelists = async () => {
    try {
      const res = await axios.get('https://api.jatillforsvar.se/api/pricelists');
      setPricelists(res.data);
    } catch (error) {
      console.error("Error fetching pricelists:", error);
    }
  };

  const handleOpen = () => {
    setFormData({ name: '', clientId: '', priceListItems: {} });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      await axios.post('https://api.jatillforsvar.se/api/pricelists', formData);
      fetchPricelists();
      handleClose();
    } catch (error) {
      console.error("Error adding pricelist:", error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Pricelists
      </Typography>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Add Pricelist
      </Button>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Client ID</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pricelists.map((pl) => (
              <TableRow key={pl.pricelistId}>
                <TableCell>{pl.pricelistId}</TableCell>
                <TableCell>{pl.name}</TableCell>
                <TableCell>{pl.clientId}</TableCell>
                <TableCell>{pl.active ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  {/* Implement Edit and Delete functionalities */}
                  <Button color="secondary">Edit</Button>
                  <Button color="error">Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add Pricelist Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Pricelist</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            name="name"
            type="text"
            fullWidth
            variant="standard"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Client ID"
            name="clientId"
            type="text"
            fullWidth
            variant="standard"
            value={formData.clientId}
            onChange={handleChange}
          />
          {/* Implement a dynamic form for priceListItems or use a separate component */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Add</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Pricelists;
