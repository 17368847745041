import React, {useState} from "react";
import { TextField, Autocomplete } from "@mui/material";

const AddressInput = ({ label, value, onSelect }) => {
    const [options, setOptions] = React.useState([]);
    const [inputValue, setInputValue] = useState(""); // Track the input field's value

    // const handleInputChange = async (event, newInputValue) => {
    //     if (newInputValue.length < 3) {
    //         setOptions([]);
    //         return;
    //     }

    //     try {
    //         // Replace with your actual API call to fetch address suggestions
    //         const response = await fetch(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${newInputValue}&key=AIzaSyD7lNV0i0kN_Njaznu3qMn54B0Tk80xB_c`);
    //         const data = await response.json();
    //         if (data.status === "OK") {
    //             setOptions(data.predictions);
    //         } else {
    //             setOptions([]);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching address suggestions:", error);
    //         setOptions([]);
    //     }
    // };


    const handleInputChange = async (event, newInputValue) => {
        setInputValue(newInputValue); // Update input value

        if (newInputValue.length < 3) {
            setOptions([]); // Clear options for short inputs
            return;
        }

        try {
            // Use the backend proxy to fetch address suggestions
            const response = await fetch(`https://api.jatillforsvar.se/api/maps/autocomplete?input=${newInputValue}`);
            const data = await response.json();
            if (data.status === "OK") {
                setOptions(data.predictions);
            } else {
                setOptions([]);
            }
        } catch (error) {
            console.error("Error fetching address suggestions:", error);
            setOptions([]);
        }
    };


    const handleOptionSelect = (event, newValue) => {
        if (newValue) {
            setInputValue(newValue.description); // Set the selected value in the input
            setOptions([]); // Clear suggestions after selection
            onSelect(newValue.description); // Notify parent component
        }
    };

    return (
        <Autocomplete
            freeSolo
            options={options}
            getOptionLabel={(option) => option.description || ""}
            onInputChange={handleInputChange} // Handle input changes
            onChange={handleOptionSelect} // Handle selection of an option
            inputValue={inputValue} // Bind the input value
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    required
                    fullWidth
                />
            )}
        />
    );
};

export default AddressInput;
