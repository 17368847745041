import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { 
  Container, 
  Typography, 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField 
} from '@mui/material';

function Drivers() {
  const [drivers, setDrivers] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', phoneNumber: '', truckType: '', size: 0 });

  useEffect(() => {
    fetchDrivers();
  }, []);

  const fetchDrivers = async () => {
    try {
      const res = await axios.get('https://api.jatillforsvar.se/api/drivers');
      setDrivers(res.data);
    } catch (error) {
      console.error("Error fetching drivers:", error);
    }
  };

  const handleOpen = () => {
    setFormData({ name: '', phoneNumber: '', truckType: '', size: 0 });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      await axios.post('https://api.jatillforsvar.se/api/drivers', formData);
      fetchDrivers();
      handleClose();
    } catch (error) {
      console.error("Error adding driver:", error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Drivers
      </Typography>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Add Driver
      </Button>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Truck Type</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {drivers.map((driver) => (
              <TableRow key={driver.id}>
                <TableCell>{driver.id}</TableCell>
                <TableCell>{driver.name}</TableCell>
                <TableCell>{driver.phoneNumber}</TableCell>
                <TableCell>{driver.truckType}</TableCell>
                <TableCell>{driver.size}</TableCell>
                <TableCell>
                  {/* Implement Edit and Delete functionalities */}
                  <Button color="secondary">Edit</Button>
                  <Button color="error">Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add Driver Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Driver</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            name="name"
            type="text"
            fullWidth
            variant="standard"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Phone Number"
            name="phoneNumber"
            type="text"
            fullWidth
            variant="standard"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Truck Type"
            name="truckType"
            type="text"
            fullWidth
            variant="standard"
            value={formData.truckType}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Size"
            name="size"
            type="number"
            fullWidth
            variant="standard"
            value={formData.size}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Add</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Drivers;
