import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { 
  Container, 
  Typography, 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField 
} from '@mui/material';

function Articles() {
  const [articles, setArticles] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({ articleNumber: '', articleName: '', category: '' });

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    try {
      const res = await axios.get('https://api.jatillforsvar.se/api/articles');
      setArticles(res.data);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  const handleOpen = () => {
    setFormData({ articleNumber: '', articleName: '', category: '' });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      await axios.post('https://api.jatillforsvar.se/api/articles', formData);
      fetchArticles();
      handleClose();
    } catch (error) {
      console.error("Error adding article:", error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Articles
      </Typography>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Add Article
      </Button>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Article Number</TableCell>
              <TableCell>Article Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {articles.map((article) => (
              <TableRow key={article.id}>
                <TableCell>{article.id}</TableCell>
                <TableCell>{article.articleNumber}</TableCell>
                <TableCell>{article.articleName}</TableCell>
                <TableCell>{article.category}</TableCell>
                <TableCell>
                  {/* Implement Edit and Delete functionalities */}
                  <Button color="secondary">Edit</Button>
                  <Button color="error">Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add Article Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Article</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Article Number"
            name="articleNumber"
            type="text"
            fullWidth
            variant="standard"
            value={formData.articleNumber}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Article Name"
            name="articleName"
            type="text"
            fullWidth
            variant="standard"
            value={formData.articleName}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Category"
            name="category"
            type="text"
            fullWidth
            variant="standard"
            value={formData.category}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Add</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Articles;
