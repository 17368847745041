import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme, useMediaQuery } from '@mui/material';

const Navigation = () => {
  const location = useLocation(); // Detect current route for active styling
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Breakpoint for responsiveness

  const navLinks = [
    { label: 'Clients', path: '/clients' },
    { label: 'Pricelists', path: '/pricelists' },
    { label: 'Orders', path: '/orders' },
    { label: 'Drivers', path: '/drivers' },
    { label: 'Articles', path: '/articles' },
    { label: 'TMS Order', path: '/tms-order' },
    { label: 'Driver View', path: '/driver-view' },
  ];

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#ED242A', boxShadow: 1 }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* App Title */}
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            color: '#ffffff',
            textShadow: '0px 2px 4px rgba(0,0,0,0.2)',
          }}
        >
          ByggBoxDemo
        </Typography>

        {/* Desktop Navigation */}
        {!isMobile ? (
          <Box sx={{ display: 'flex', gap: 2 }}>
            {navLinks.map((link) => (
              <Button
                key={link.path}
                component={Link}
                to={link.path}
                sx={{
                  color: location.pathname === link.path ? '#FFFFFF' : '#ffffff',
                  textTransform: 'none',
                  fontWeight: location.pathname === link.path ? 'bold' : 'normal',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    color: '#FFFFFF',
                  },
                  transition: 'all 0.3s ease',
                  borderBottom: location.pathname === link.path
                    ? '2px solid #FFFFFF'
                    : '2px solid transparent',
                }}
              >
                {link.label}
              </Button>
            ))}
          </Box>
        ) : (
          // Mobile Navigation (Hamburger Menu)
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
              <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                <List>
                  {navLinks.map((link) => (
                    <ListItem
                      button
                      key={link.path}
                      component={Link}
                      to={link.path}
                      sx={{
                        backgroundColor:
                          location.pathname === link.path ? 'rgba(237, 36, 42, 0.1)' : 'transparent',
                        '&:hover': {
                          backgroundColor: 'rgba(237, 36, 42, 0.2)',
                        },
                      }}
                    >
                      <ListItemText
                        primary={link.label}
                        primaryTypographyProps={{
                          style: {
                            fontWeight: location.pathname === link.path ? 'bold' : 'normal',
                            color: '#ED242A',
                          },
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Drawer>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
